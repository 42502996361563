/* @import "./css/slick.css"; */
@import "./css/aos.css";
@import "./css/bootstrap-tagsinput.css";
@import "./css/hover-min.css";
@import "./css/hover.css";
@import "./css/main.css";
@import "./../node_modules/bootstrap/dist/css/bootstrap.min.css";
@import "./../node_modules/@fortawesome/fontawesome-free/css/all.css";
@import './../node_modules/antd/dist/antd.css';


body {
	color: #1a1a1a;
	font-family: "Opensans";
	font-size: 17px;
	position: relative;
    top: unset !important;
    overflow-x: hidden;
}
ol, ul {
	margin: 0;
	padding: 0;
	list-style: none
}
a, a:focus, a:hover {
	text-decoration: none;
	outline: 0
}
.btn,button{
	font-family: 'Opensans-bold';
	
}
.btn.active.focus, .btn.active:focus, .btn.focus, .btn.focus:active, .btn:active:focus, .btn:focus, button:focus {
	text-decoration: none;
	outline: 0;
	box-shadow: none;;
}
.image-error{
    font-size: 10px;
    color: red;
}
h2, h3, h4, h5, h6 {
	font-family: "BebasNeue";
	color: #fff;
	margin-bottom: 0;
	margin-top: 0;
}
.h2, .h3, .h4, .h5, .h6 {
	font-family: "BebasNeue";
	color: #fff;
	margin-bottom: 0;
	margin-top: 0;
}
h1, .h1 {
	font-size: 70px;
    font-family: "Exo-semibold";
    color: #fff;
	margin-bottom: 0;
	margin-top: 0;
}
h2, .h2 {
	font-size: 40px;
	color: #281489;
}
h3, .h3 {
	font-size: 36px
}
h4, .h4 {
	font-size: 30px
}
h5, .h5 {
	font-size: 24px
}
h6, .h6 {
	font-size: 20px;
}
td, th {
	border-radius: 0px
}
.clear::before, .clear::after {
	content: " ";
	display: table
}
.clear::after {
	clear: both
}
.text-white {
	color: #fff
}
.text-black {
	color: #000
}
.transition {
	-webkit-transition: all 0.3s ease 0s;
	-moz-transition: all 0.3s ease 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s
}
.main-menu .navbar{
	background-color: rgba(29,17,79,.82);
	filter: drop-shadow(-4.17px 4.32px 24px #00000085);
}
.main-padding{
	padding: 10px 70px;
}
.navbar .nav-item .nav-link{
	color: #fff;
	font-family: 'Opensans-bold';
	padding: 0 25px;
}
.navbar .navbar-brand {
    padding-bottom: 0;
}
.navbar .navbar-brand .logo{
    width: 62px;
}
.btn-default{
	background: transparent linear-gradient(90deg, #643DAA 0%, #E85993 100%) 0% 0% no-repeat padding-box;
	border-radius: 25px;
	color: #fff;
	padding: 12px 20px;
    border: none;
    display: flex !important;
    align-items: center;
    justify-content: center;
    height: 60px;
}
.btn-default:hover{
    color: #fff;
}
.btn-default a{
    color: #fff;
}
.btn-default .icon, .btn .icon {
	display: inline-block;
	margin-right: 7px;
}
.btn-default .icon img, .btn .icon img{
    width: 18px;
}
.btn-primary {
    background: none;
    color: #251667;
    border: 1px solid #251667;
    font-size: 15px;
    padding: 8px 20px;
    border-radius: 8px;
    font-weight: bold;
}
.btn-primary:hover , .btn-primary:focus , .btn-primary.active{
    background: #251667 !important;
    color: #fff !important;
    box-shadow: none !important;
}
.custom-items{
	display: flex;
	align-items: center;
	margin-left: 25px;
	margin-right: 70px;
}
.custom-items .btn-default{
    height: auto;
}
.login-signup , .login-signup a{
	color: #fff;
}
.flags-dropdown .dropdown-menu {
    min-width: 80px;
    right: 0;
    left: auto;
}
.flags-dropdown {
    margin: 0 25px;
}
.flags-dropdown .dropdown-item {padding: 6px 15px;width: 120px;display: flex;align-items: center;justify-content: space-between;font-size: 15px;color: #474a4df0;font-weight: 500;}

.flags-dropdown .dropdown-item .flag {
    width: 22px;
}
.flags-dropdown .dropdown-item.english {
    padding: 4px 15px 4px 7px;
}
.flags-dropdown .dropdown-item:focus,.flags-dropdown  .dropdown-item:hover{
    background-color: transparent;
}
.artist-list {
    /* display: flex;
    flex-wrap: wrap; */
}
.artist-list .artist {
    width: calc(100%/3);
	margin: 0;
	position: relative;
    margin-bottom: -7px;
}
.artist-list .artist .content {
    position: absolute;
    bottom: 48px;
    left: 0;
    right: 0;
    width: 100%;
    text-align: center;
    color: #fff;
	padding: 0 40px;
}
.artist-list .artist .content h2{
	margin-bottom: 20px;
	color: #fff;
}
.artist-list .artist .content p{
	margin-bottom: 20px;
}
.artist-list .artist .content .btn-default {
    height: 60px;
    align-items: center;
    display: flex;
    margin: 0 auto;
    padding: 10px 30px;
}
.art-commerce.main-padding {
    background: url('./assets/img/art-commerce-bg.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
    padding-bottom: 175px;
    padding-top: 40px;
	position: relative;
	overflow: hidden;
}
.art-commerce::before {
    content: '';
    background: url('./assets/img/art-commerce-before.svg');
    background-repeat: no-repeat;
    position: absolute;
    top: 40px;
    height: 100%;
    width: 100%;
    left: 140px;
    margin: 0 auto;
}
.art-commerce .content p {
    margin-top: 26px;
	margin-bottom: 50px;
    font-size: 20px;
    line-height: 38px;
    color: white;
}
.art-commerce .content {
    margin-bottom: 20px;
}
.art-commerce .content .btn-default{
    height: 60px;
    align-items: center;
    display: flex;
    padding: 10px 30px;
}
.creater-investor{
	padding: 0 0 70px;
}
.creater-investor .content p {
    color: #1A1A1A;
    margin-top: 15px;
    margin-bottom: 30px;
}
.content .cards {
    display: flex;
    margin-top: 20px;
}
.content .cards .boxes-wrapper{
    width: 250px;
    height: 245px;
    margin-right: 26px;
    transition: 1s;
}
.content .cards .boxes {
    display: inline-block;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    padding: 25px;
    cursor: pointer;
    height: 100%;
    width: 100%;
}
.content .cards .points {
    /* width: 250px; */
    margin-right: 26px;
}
.content .cards .boxes-wrapper:hover .boxes span {
    transform: rotateY(180deg);
    transition: 1s;
}
.content .cards .boxes-wrapper:hover .boxes {
    transform: rotateY(180deg);
    transition: 1s;
}
.content .cards .creater {
    background: transparent linear-gradient(90deg, #0196FF 0%, #28EEDC 100%) 0% 0% no-repeat padding-box;
}
.content .cards .investor {
    background: transparent linear-gradient(90deg, #643DAA 0%, #F05A91 100%) 0% 0% no-repeat padding-box;
}
.content .cards .boxes .h3{
	/* margin-bottom: 15px; */
    font-size: 47px;
}
.timer-wrapper {
    background: transparent linear-gradient(143deg, #1D114F 0%, #3A24A6 100%) 0% 0% no-repeat padding-box;
    border-radius: 10px;
    padding: 25px 40px;
    text-align: center;
    color: #fff;
	display: flex;
    flex-flow: column;
    justify-content: center;
	height: 100%;
    margin: 0 auto;
}
.timer-wrapper .timer {
    background: #0000001A 0% 0% no-repeat padding-box;
    border: 1px solid #9960C9;
    border-radius: 4px;
    margin-top: 26px;
    margin-bottom: 30px;
    padding: 10px;
    font-size: 36px;
    font-family: 'Opensans-bold';
    display: flex;
    align-items: center;
    justify-content: center;
}
.timer-wrapper .timer>span {
    border-right: 1px solid #9960C9;
    display: flex;
    flex-flow: column;
    padding: 0 10px;
    flex: 1;
}
.timer-wrapper .timer span:last-child{
	border: none;
}
.timer-wrapper .timer span small {
    font-size: 12px;
}
.timer-wrapper .btn-default {
    width: fit-content;
    margin: 0 auto;
}
section.about-why .image {
    transform: rotateY(180deg);
    text-align: center;
}
section.about-why .content {
    text-align: right;
}
.about-why .content p {
    color: #1A1A1A;
    margin-top: 15px;
    margin-bottom: 30px;
}
.about-icons {
    display: flex;
    margin-top: 80px;
}
.about-icons .icon {
    /* width: 130px;
    border-radius: 37px; */
    transform: rotate(135deg);
}
.about-icons .slider {
    -webkit-appearance: none;
    width: 130px;
    outline: none;
    border-radius: 37px;
    box-shadow: -3px 0px 13px 0px rgb(0 0 0 / 26%);
}
.about-icons .first .slider {
    background: linear-gradient( 90deg, rgb(1,150,255) 0%, rgb(40,238,220) 100%);
}
.about-icons .first .slider::-webkit-slider-thumb {
    background: url('./assets/img/about-icon-1.png');
    background-repeat: no-repeat;
    background-size: 100%;
}
  .about-icons .second .slider {
    background: linear-gradient( 90deg, rgb(122,92,207) 0%, rgb(70,122,220) 100%);
}
.about-icons .second .slider::-webkit-slider-thumb {
    background: url('./assets/img/about-icon-2.png');
    background-repeat: no-repeat;
    background-size: 100%;
}
  .about-icons .third .slider {
    background: linear-gradient( 0deg, rgb(100,61,170) 0%, rgb(232,89,147) 100%);
}
.about-icons .third .slider::-webkit-slider-thumb {
    background: url('./assets/img/about-icon-3.png');
    background-repeat: no-repeat;
    background-size: 100%;
}
  .about-icons .fourth .slider {
    background: linear-gradient( 71deg, rgb(29,17,79) 0%, rgb(58,36,166) 100%);
}
.about-icons .fourth .slider::-webkit-slider-thumb {
    background: url('./assets/img/about-icon-4.png');
    background-repeat: no-repeat;
    background-size: 100%;
}
.about-icons .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 71px;
    height: 71px;
    background: #4CAF50;
    cursor: pointer;
    transform: rotate(-135deg);
}
.about-icons .slider::-moz-range-thumb {
    width: 71px;
    height: 71px;
    background: #4CAF50;
    cursor: pointer;
    background: url('./assets/img/about-icon-1.png');
    transform: rotate(-135deg);
}
.about-icons .icon img {
    transform: rotate(-135deg);
}
.about-why .follow-us {
    margin-top: 60px;
    text-align: center;
}
.about-why  .btn-shadow {
    box-shadow: 0px 1px 10px 10px rgb(0 0 0 / 50%);
    border-radius: 62px;
    display: inline-block;
}
.about-why .follow-us .btn.follow {
    font-size: 20px;
    border-color: #fff;
    box-shadow: 0px 0px 18px #171717;
    background-color: #1C1152;
    width: auto;
    padding: 5px 25px;
}
.about-why .follow-us .btn.follow span{
    border: none;
    color: #fff;
}
  .most-watched .section-heading {
    margin-bottom: 20px;
}
.most-watched .image {
    height: 70vh;
    max-height: 600px;
}
.most-watched .image img {
    height: 100%;
    object-fit: cover;
}
  .check-range .items--list {
    display: flex;
    flex-wrap: wrap;
    height: 100%;
}
.items--list .item {
    width: 33.3%;
    position: relative;
	margin: 0;
}
.items--list .item .content {
    position: absolute;
    left: 0;
    bottom: 0;
    width: calc(100% - 42px);
    height: calc(100% - 45px);
    display: flex;
    top: 0;
    align-items: flex-end;
    border: 3px solid #fff;
    margin: auto;
    right: 0;
    border-radius: 2px;
}
.items--list .item .content .detail {
    background: #00000080;
    width: 100%;
    color: #fff;
    padding: 10px 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}
.items--list .item .content .detail > div {
    flex: 1;
}
.items--list .item .content .detail p {
    margin: 0;
}
.getting-started{
    padding: 90px 0 35px;
}
.getting-started .content p {
    color: #1A1A1A;
    margin-top: 15px;
}
.getting-started .video iframe{
    max-width: 100% !important;
}
.points {
    margin-bottom: 40px;
    margin-top: 40px;
    font-family: 'Opensans-bold';
}
.points ul li {
    color: #1A1A1A;
    margin-bottom: 10px;
    font-size: 17px;
    padding-left: 25px;
    position: relative;
}
.points ul li::before {
    content: '';
    width: 8px;
    height: 8px;
    border: 2px solid #281489;
    border-radius: 50%;
    background: white;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto 0;
}
.items--list.full .item {
    width: 100%;
}
.section-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.check-range .section-heading {
    margin-bottom: 15px;
}
.section-heading .nav-tabs{
    border: none;
}
.section-heading .nav-tabs .nav-link {
    border: 2px solid #968cc5;
    margin-right: 10px;
    border-radius: 25px;
    color: #281489;
    padding: 12px 30px;
    min-width: 150px;
    text-align: center;
    font-family: 'Opensans-bold';
}
.section-heading .nav-tabs .nav-link img {
    margin-right: 10px;
    width: 18px;
}
.section-heading .nav-tabs .nav-link.active, .section-heading .nav-tabs .nav-link:hover {
    background-image: linear-gradient(0deg, rgb(40,20,137) 0%, rgb(99,77,208) 100%);
    color: #fff;
    box-shadow: 0px 0px 0px 2px #968cc5;
    border: 2px solid #ffffff;
}
.section-heading .nav-tabs .nav-link .active {
    display: none;
}
.section-heading .nav-tabs .nav-link.active .color , .section-heading .nav-tabs .nav-link:hover .color {
    display: none;
}
.section-heading .nav-tabs .nav-link.active .active , .section-heading .nav-tabs .nav-link:hover .active {
    display: inline-block;
}
.check-range .items--list .item .content .detail ,.check-range .items--list .item .content .artist-detail {
    display: none;
}
.check-range .items--list .item:hover .content .detail{
    display: flex;
}
.check-range .items--list .item:hover .content .artist-detail{
    display: flex;
}
.check-range .items--list .item .content .detail {
    background: none;
    justify-content: flex-start;
    align-items: center;
    padding: 0;
}
.check-range .items--list .item .content .detail>div {
    background: #22145E;
    padding: 20px;
    min-width: 190px;
    margin-right: 27px;
    word-break: break-all;
    flex: unset;
}
.check-range .items--list .item .content .detail>div p{
    font-size: 15px;
}
.check-range .items--list .item .content {
    position: absolute;
    left: 0;
    bottom: 0;
    width: calc(100% - 42px);
    height: calc(100% - 52px);
    display: flex;
    flex-flow: column;
    top: 21px;
    border: 3px solid #fff;
    margin: 0 auto;
    right: 0;
    border-radius: 2px;
    justify-content: space-between;
}
.check-range .items--list .item:hover .content{
    background: #00000080;
}
.check-range .items--list.full .item .image {
    /* height: 70vh;
    max-height: 700px; */
}
.artist-detail {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    color: #fff;
    width: 100%;
}
.artist-detail .number span {
    display: flex;
    background: #fff;
    color: #242328;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-family: 'Opensans-bold';
    word-break: break-all;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: none;
}
.check-range .items--list.full{
    height: 100%;
}
.check-range .items--list.full .item {
    /* height: 100%; */
    height: 700px;
    /* max-height: 700px; */
}
.check-range .items--list .item:nth-child(3), .check-range .items--list .item:nth-child(6){
    padding-right:0;
}
.check-range .items--list .item{
    padding-bottom: 10px;
    padding-right: 5px;
    padding-left: 0;
    height: 350px;
}
.check-range .items--list .item .image {
    height: 100%;
}
.check-range .items--list .item .image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.check-range .items--list.full .item .image img {
    height: 100%;
    object-fit: cover;
}
section.nexus-guide {
    padding: 100px 0 80px;
}
.nexus-guide .section-heading {
    margin-bottom: 50px;
    position: relative;
}
.nexus-guide .video-title{
    margin-top: 20px;
    background-color: rgb(255, 255, 255);
    box-shadow: -0.366px 2.978px 5.88px 1.12px rgba(0, 0, 0, 0.07);
    padding: 5px 10px;
    border-left: 2px solid #281489;
    margin-bottom: 20px;
}
.nexus-guide .video-title p{
    color: #1A1A1A;
    margin: 0;
}
.slider-arrow {
    background: no-repeat;
    border: none;
    color: #281489;
    visibility: hidden;
}
.nexus-guide .section-heading h2 ,.nexus-guide .section-heading  .slider-arrows {
    background-color: #fff;
}
.nexus-guide .section-heading .slider-arrows {
    padding-left: 10px;
}
.nexus-guide .section-heading h2 {
    padding-right: 25px;
}
.nexus-guide .section-heading::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    background: #e5e5e5;
    z-index: -1;
}
.videos-slider .slick-arrow {
    position: absolute;
    right: 0;
    top: -84px;
    cursor: pointer;
}
.videos-slider .slick-arrow.next {
    right: 21px;
}
.videos-slider .slick-arrow.prev {
    right: 51px;
}
.blockchain .h1-title {
    font-size: 70px;
    color: #281489;
    font-family: 'Exo-semibold';
    line-height: 84px;
    margin-bottom: 50px;
}
.blockchain .content p{
    color: #1A1A1A;
}
.blockchain .content button{
    margin-top: 40px;
}
.blockchain .image {
    border-radius: 50%;
    background-color: #0d9be8;
    box-shadow: 0px 0px 25px 12px #0d9be8;
    width: fit-content;
}
.blockchain .image img{
    box-shadow: 0px 0px 18px #f4129c;
    border: 6px solid;
    border-color: #e8fcff;
    border-radius: 50%;
}
footer{
    background-image: url('./assets/img/footer-bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
    padding-top: 500px;

}
.blockchain ~ .footer{
    margin-top: -370px;
}
.social-links {
    margin-bottom: 55px;
}
.social-links ul {
    display: flex;
    justify-content: center;
}
.social-links ul li {
    margin-right: 15px;
}
.footer-links {
    margin-bottom: 0;
    cursor: pointer;
}
.platform-icons {
    text-align: center;
    margin-bottom: 20px;
    margin-top: 20px;
}
.platform-icons .image {
    width: 150px;
    margin: 0 auto;
}
.footer-links ul{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
.footer-links ul li a{
    color: #fff;
    padding: 0 20px;
}
.follow-us {
    text-align: center;
    margin-bottom: 80px;
}
.btn-shadow{
    box-shadow: 0px 0px 20px 8px #0d9be8;
    border-radius: 62px;
    display: inline-block;
}
.follow-us .btn.follow {
    font-size: 40px;
    color: white;
    /* font-family: 'Exo-semibold'; */
    border: 6px solid;
    border-color: #e8fcff;
    background-color: rgba(7, 2, 32, 0.2);
    width: 450px;
    height: 130px;
    max-width: 100%;
    border-radius: 62px;
    box-shadow: 0px 0px 18px #f4129c;
    padding: 8px;
}   
.follow-us .btn.follow span{
    width: 100%;
    height: 100%;
    display: flex;
    border: 1px solid #707070;
    border-radius: 62px;
    align-items: center;
    justify-content: center;
}
.footer-bottom {
    background: #2C1276;
    position: relative;
}
.footer-bottom .container{
    position: relative;
}
.copyrights p {
    text-align: center;
    font-size: 15px;
    color: #D8D2E7;
    padding: 15px 0;
    margin: 0;
}
.chat-icon {
    position: absolute;
    right: -90px;
    bottom: 20px;
    cursor: pointer;
}
.company-logo {
    position: absolute;
    right: 0;
    bottom: 0;
}
.custom-items .mobile.login-signup {
    display: none;
}
.login-signup .modal-content {
    border-radius: 10px;
}
.login-signup .modal-body {
    padding: 0;
}
.col-md-4.left {
    padding: 0;
    position: relative;
}
.login-signup-wrapper .image {
    height: 80vh;
}
.login-signup-wrapper .image::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: #1d114f8c;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}
.login-signup-wrapper .image img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}
.login-signup-wrapper .nav-pills {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    justify-content: center;
}
.login-signup-wrapper .nav-pills .nav-link{
    font-family: 'OpenSans-Bold';
    padding: 10px 25px;
}
.login-signup-wrapper .nav-pills .nav-link.active,.login-signup-wrapper .nav-pills .show>.nav-link {
    color: #281489;
    background-color: #fff;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.login-signup .modal-body .content-wrapper {
    height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.modal-body .content-wrapper .tab-content {
    height: 100%;
    /* margin-bottom: 10px; */
    width: 100%;
    overflow: hidden;
    overflow-y: auto;
    padding: 20px 0;
    display: flex;
}
.modal-body .content-wrapper .tab-content .tab-pane{
    margin: auto;
    width: 100%;
}
.modal-body .content-wrapper .form-wrapper {
    max-width: 100%;
    width: 350px;
    margin: auto;
}
.content-wrapper .form-wrapper .logo {
    width: 80px;
    margin: 0 auto 20px;
}
.form-group label , .form-check .form-check-label {
    color: #1A1A1A;
}
.form-group label{
    font-family: 'Opensans-bold';
}
.form-group .form-control {
    outline: none;
    box-shadow: none;
    border: 1px solid #e5e5e5;
    height: 45px;
}
.form-group textarea.form-control{
    height: auto;
}
.content-wrapper .form-check {
    margin-bottom: 10px;
}
.form-check .form-check-label {
    font-size: 14px;
}
.content-wrapper .form-wrapper .forget{
    color: #281489;
    display: inline-block;
    font-family: 'Opensans-bold';
}
.form-check .form-check-label a{
    color: #281489;
    font-family: 'Opensans-bold';
}
.content-wrapper .form-wrapper .btn-default {
    width: 100%;
    margin-top: 20px;
}
.content-wrapper .form-wrapper .btn-default:hover{
    color: #fff;
}
.content-wrapper .form-wrapper h4 {
    color: #281489;
    text-align: center;
    margin: 20px 0;
}
.account-dropdown .btn {
    color: #fff;
}
.account-dropdown .btn .img{
    margin-right: 5px;
}
.account-dropdown .dropdown-menu{
    background-color: rgba(29,17,79,.82);
}
.account-dropdown .dropdown-menu .dropdown-item:focus,.account-dropdown .dropdown-menu .dropdown-item:hover , .account-dropdown .dropdown-menu .dropdown-item.active {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa;
}
.dashboard-wrapper.main-padding , .main-wrapper.main-padding {
    padding-top: 150px;
}
.sidebar {
    background: transparent linear-gradient(143deg, #1D114F 0%, #3A24A6 100%) 0% 0% no-repeat padding-box;
    border-radius: 10px;
    padding: 20px 0;
}
.sidebar .profile{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
}
.sidebar hr{
    border-color: #777;
    width: 80%;
}
.sidebar .profile-img {
    text-align: center;
    margin-bottom: 10px;
    margin-right: 10px;
    width: 100px;
    height: 100px;
}
.profile-img img {
    border-radius: 50%;
    border: 1px solid #ccc;
    height: 100%;
    object-fit: cover;
}
.sidebar .profile-info {
    text-align: left;
}
.sidebar .profile-info p{
    color: #eee;
}
.sidebar .other-info{
    padding: 20px 0;
}
.sidebar .other-info .address.btn-default{
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: block !important;
}
.sidebar .other-info .btn-default {
    margin: 0 auto 10px;
    width: 80%;
}
.sidebar .other-info .btn-default:hover{
    color: #fff;
}
.sidebar .links-list{
    padding: 20px 0;
}
.links-list ul li {
    text-align: right;
}
.links-list ul li a {
    color: #fff !important;
    display: inline-block;
    padding: 12px 25px;
    width: 80%;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    text-align: left;
    font-family: 'Opensans-bold';
}
.links-list ul li a:hover, .links-list ul li a.active{
    background-color: #fff;
    color: #000 !important;
}
.links-list ul li a .icon {
    margin-right: 7px;
}
.links-list ul li .dropdown {
    display: inline-block;
    width: 100%;
    text-align: right;
}
.links-list .dropdown-menu {
    position: unset !important;
    transform: none !important;
    width: 80%;
    margin-left: auto !important;
    float: none;
    margin-top: 0;
    padding: 5px 10px;
    border: none;
}
.links-list .dropdown-menu li a {
    width: 100%;
    color: #2e1c81 !important;
}
.links-list .dropdown-menu li a:hover , .links-list .dropdown-menu li a.active{
    color: #fff !important;
    background: #2e1c81 !important;
    border-radius: 0;
}
.content-box {
    box-shadow: 0 0 8px 0px #26176a7a;
    border-radius: 10px;
    padding: 25px;
    margin-bottom: 20px;
}
.content-box h3 {
    margin-bottom: 20px;
    color: #281489;
}
.content-box h3 > a{
    color: #281489;
}
.content-box h3 .upgrade{
    display: inline-block !important;
    font-size: 13px;
}
.content-box h3 .btn:hover{
    color: #fff;
}
.content-box h5 {
    margin-bottom: 20px;
    color: #281489;
    margin-top: 30px;
}
.change-profile-img {
    text-align: center;
}
.change-profile-img .custom-file-label {
    border: none;
}
.change-profile-img .custom-file-input {
    cursor: pointer;
}
.change-profile-img .custom-file-label::after {
    display: none;
}
.custom-file-input:focus~.custom-file-label{
    box-shadow: none;
}
.button-group {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
}
.button-group button {
    width: auto !important;
    margin: 0 !important;
}
.button-group .btn-secondary {
    background: transparent;
    color: #281489;
    border-color: #281489;
    padding: 12px 20px;
    border-radius: 25px;
}
.button-group .btn-secondary:hover , .button-group .btn-secondary:focus ,.button-group .btn-secondary:active{
    background: transparent !important;
    color: #281489 !important;
    border-color: #281489 !important;
}
.content-box .content h6 {
    color: #281489;
}
.content .btn-default {
    width: fit-content;
}
.content .btn:hover{
    color: #fff;
}
.collection-sidebar {
    background: #eee;
    padding: 20px 20px;
    border-radius: 8px;
}
.collection-sidebar h3{
    color : #281489;
    margin-bottom: 30px;
}
.collection-sidebar .filters .item {
    margin-bottom: 30px;
}
.filters .item a {
    color: #1A1A1A;
    display: inline-block;
    width: 100%;
    position: relative;
}
.filters .item a:hover{
    color: #1A1A1A;
}
.filters .item .title .icon {
    font-family: 'Font Awesome 5 Free';
    font-size: 12px;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
}
.filters .item  .title .icon::before {
    content: "\f068";
}
.filters .item .collapsed .icon::before {
    content: "\f067";
}
.filters .item .filter-content {
    margin-top: 15px;
}
.filter-content .list-menu li {
    margin-bottom: 10px;
}
.filter-content .list-menu li a {
    font-size: 13px;
    font-weight: 600;
}
.filter-content .list-menu li .active {
    background: #281489;
    color: #fff;
    padding: 6px;
    border-radius: 4px;
}
.filter-content .list-menu li .active:hover{
    color: #fff;
}
.filter-content .list-menu li .active span{
    font-family: "Font Awesome 5 Free";
}
.filter-content .list-menu li .active span::before {
    content: "\f00d";
    position: absolute;
    right: 10px;
    font-weight: bold;
}
.filter-content .list-menu li a:hover{
    color: #281489;
}
.filters .item .filter-content .btn-default{
    color: #fff;
}
.filters .item .filter-content .btn-default {
    color: #fff;
    width: fit-content;
    max-width: 100%;
    margin: 0 auto;
}
.breadcrumb{
    background: none;
    padding: 0 5px;
}
.breadcrumb .breadcrumb-item a{
    color: #1A1A1A;
}
.breadcrumb .breadcrumb-item.active {
    color: #281489;
    font-family: 'Opensans-bold';
}
.category-info{
    text-align: center;
    margin-bottom: 50px;
}
.category-info h2{
    margin-bottom: 10px;
}
.category-info p{
    margin-bottom: 0;
}
.collection-filters {
    display: flex;
    justify-content: space-between;
    background: #eee;
    padding: 10px 20px;
    border-radius: 8px;
    margin-bottom: 15px;
    align-items: center;
}
.collection-filters .items-show {
    color: #281489;
    font-family: 'Exo-semibold';
}
.items-view button {
    border: 1px solid #281489;
    color: #281489;
}
.items-view button.list {
    margin-right: 5px;
}
.items-view .active ,.items-view button:hover{
    background-color: #281489;
    color: #fff;
}
.collection-items.product-grid {
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;
}
.collection-items.product-grid .product-item {
    width: 25%;
    padding: 0 10px;
    margin-bottom: 20px;
}
.collection-items .product-item .product-wrap{
    background: #eee;
    border-radius: 8px;
    height: 100%;
    position: relative;
    padding-bottom: 155px;
}
.collection-items .product-item .product-wrap .image {
    width: 100%;
    height: 300px;
}
.collection-items.product-grid .product-item .product-wrap .image img {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.collection-items.product-list .product-item {
    margin-bottom: 15px;
}
.collection-items.product-list .product-item .product-wrap {
    display: flex;
    flex-flow: row;
    padding-bottom: 0;
}
.collection-items.product-list .product-item .product-wrap .image {
    width: 25%;
}
.collection-items.product-list .product-item .product-wrap .image img {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.collection-items.product-list .product-item .product-wrap .product-info {
    width: 50%;
    padding: 15px 20px;
}
.collection-items.product-list .product-item .product-wrap .actions {
    width: 25%;
    padding-top: 15px;
    position: relative;
}
.collection-items.product-list .product-item .product-wrap .actions .price {
    text-align: left;
}
.collection-items.product-list .product-item .product-wrap .actions .btn-default {
    margin-left: 0;
}
.product-item .product-wrap .product-info {
    padding: 10px 10px;
    color: #1A1A1A;
}
.product-item .product-wrap .product-info > .title   {
    color: #281489;
    margin-bottom: 10px;
    font-family: 'Opensans-bold';
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.seller-info .item {
    margin: 0;
}
.seller-info .item .title, .product-item .product-wrap .product-info .title{
    color: #281489;
    margin-bottom: 10px;
    font-family: 'Opensans-bold';
}
.product-item .product-wrap .actions {
    padding: 0 10px 15px;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
}
.product-item .product-wrap .actions .price-wrapper {
    flex-flow: column;
    align-items: center;
}
.product-item .product-wrap .actions .btn-default {
    color: #fff;
    width: fit-content;
    margin: 0 auto;
}
.product-item .product-wrap .actions .price {
    display: inline-block;
    text-align: center;
    width: 100%;
    margin-bottom: 20px;
    font-size: 20px;
    color: #281489;
    font-family: 'Opensans-bold';
}
.price_eth{
    display: inline-block;
    text-align: center;
    width: 100%;
    margin-bottom: 2px;
    font-size: 14px;
    color: #0c0c0c;
    font-family: 'Opensans-bold';
}
.collection-pagenation {
    background: #eee;
    padding: 10px 20px;
    border-radius: 8px;
}

.pagination .page-item , .ant-pagination .ant-pagination-item {
    margin-right: 10px;
    border-radius: 6px;
}
.pagination .page-item .page-link , .ant-pagination .ant-pagination-item a{
    border-color: #ccc;
    color: #281489;
    border-radius: 0;
}
.pagination .page-item.active .page-link , .pagination .page-item .page-link:hover , .ant-pagination .ant-pagination-item-active a , .ant-pagination .ant-pagination-item a:hover {
    border-color: #281489;
    background-color: #281489;
    color: #fff;
}
.pagination .page-item .page-link:focus , .ant-pagination .ant-pagination-item a:focus{
    box-shadow: none;
}
.ant-pagination-prev .ant-pagination-item-link, .ant-pagination-next .ant-pagination-item-link {
    display: flex;
    align-items: center;
    justify-content: center;
}
.ant-pagination-prev:focus-visible .ant-pagination-item-link, .ant-pagination-next:focus-visible .ant-pagination-item-link, .ant-pagination-prev:hover .ant-pagination-item-link, .ant-pagination-next:hover .ant-pagination-item-link {
    color: #fff;
    border-color: #281489;
    background: #281489;
}
.product-image .carousel-inner img {
    width: 100%;
    height: 100%
}
#custCarousel .carousel-indicators {
    position: static;
    margin-top: 20px
}
#custCarousel .carousel-indicators>li {
    width: 100px;
    height: 70px;
}
#custCarousel .carousel-indicators li img {
    display: block;
    opacity: 0.5;
    height: 100%;
    object-fit: cover;
}
#custCarousel .carousel-indicators li.active img {
    opacity: 1
}
#custCarousel .carousel-indicators li:hover img {
    opacity: 0.75
}
.main-wrapper.product .product-image .carousel , .main-wrapper.product .product-image .carousel-inner , .main-wrapper.product .product-image .carousel-inner  .carousel-item{
    height: 100%;
}
.carousel-control-next-icon, .carousel-control-prev-icon {
    height: 50px;
    background-color: #666;
}
.main-wrapper.product .product-image .carousel {
    height: calc(100% - 120px);
}
.main-wrapper.product .product-image {
    margin-bottom: 20px;
    max-height: 750px;
    height: 100%;
}
.main-wrapper.product .product-image img {
    height: 100%;
    object-fit: contain;
    width: 100%;
}
.product-image .carousel-item img {
    width: 100%
}
.product-detail {
    background: #eee;
    border-radius: 8px;
    padding: 20px;
}
.product-detail .product-title{
    color: #281489;
}
.product-detail .sub-title {
    color: #1a1a1a;
}
.product-detail .qty-left {
    color: #E85993;
    font-weight: 600;
}
.product-detail .product-price {
    margin-bottom: 20px;
    margin-top: 20px;
}
.product-detail .product-price .price {
    font-size: 20px;
    color: #281489;
    font-family: 'Opensans-bold';
}
.product-detail .product-price .price .eth {
    font-family: 'Opensans';
    font-size: 14px;
    color: #1a1a1a;
}
.bid-section {
    padding: 10px 15px;
    margin-top: 10px;
    max-width: 500px;
    box-shadow: 0px 0px 5px 0px #281489;
    border-radius: 8px;
}
.bid-section h4 {
    color: #281489;
}
.bid-section .form-control {
    outline: none;
    box-shadow: none;
    border: 1px solid #e5e5e5;
    height: 45px;
}
.bid-section .seller-info{
    margin-top: 10px;
}
.bid-section .btn-default{
    margin-top: 20px;
}
.product-detail .seller-info {
    display: flex;
    flex-wrap: wrap;
}
.product-detail .seller-info .item {
    width: 50%;
    margin-bottom: 5px;
}
.product-detail .reviews {
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
}
.reviews .fas {
    color: #f4c006;
}
.product-detail .description {
    margin-top: 20px;
}
.product-detail .description h5 {
    color: #281489;
    margin-bottom: 5px;
}
.product-detail .social-links{
    margin-bottom: 0;
    margin-top: 20px;
}
.product-detail .social-links ul {
    justify-content: flex-start;
}
.product-detail .social-links ul li{
    margin-right: 5px;
}
.product-detail .social-links ul li img {
    width: 50px;
}
.product-detail .add-to-cart {
    margin-bottom: 20px;
}
.product-detail .btn-default {
    width: fit-content;
    max-width: 100%;
}
.modal-content .close {
    position: absolute;
    right: 10px;
    top: 10px;
    background: #fff;
    border: 1px solid;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #281489;
    opacity: 1 !important;
    margin: 0;
    z-index: 9;
}
.modal-content .close:hover {
    background: #281489;
    border: 1px solid;
    color: #fff;
}
.product-detail .report {
    text-align: center;
    margin-top: 20px;
}
.product-detail .report p{
    margin: 0;
}
.product-detail .report button{
    margin-left: 5px;
    padding: 6px 12px;
    border-color: transparent;
    border-radius: 4px;
}
.report-issues-list {
    padding: 10px 20px;
}
.form-check label span {
    font-weight: bold;
}
.modal-content .modal-title {
    text-align: center;
    margin-bottom: 20px;
}
.modal-title h4 {
    color: #281489;
    margin-bottom: 5px;
}
.modal-title p{
    margin-bottom: 0;
}
.report-issues-list .btn-default{
    margin-left: auto;
}
.report-issues-list .btn-default:hover{
    color: #fff;
}
.related-products {
    margin-top: 100px;
}
.related-products .section-title {
    margin-bottom: 20px;
    text-align: center;
}
.related-product-list {
    display: flex;
    margin-right: -10px;
    margin-left: -10px;
    flex-wrap: wrap;
}
.related-product-list .product-item {
    width: 20%;
    padding: 0 10px;
}
.related-product-list .product-wrap {
    background: #eee;
    border-radius: 8px;
    position: relative;
    padding-bottom: 125px;
}
.related-product-list .product-wrap img{
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}
.related-product-list .slick-arrow {
    background: #fff;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 50px;
    height: 50px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin: auto;
    cursor: pointer;
    z-index: 9;
    opacity: 0.7;
}
.related-product-list .slick-arrow:hover{
    opacity: 1;
}
.related-product-list .prev.slick-arrow{
    left: 15px;
}
.related-product-list .next.slick-arrow{
    right: 15px;
}
.item-metadata {
    background: #eee;
    border-radius: 8px;
    padding: 0px 20px 20px;
    margin-top: 20px;
}
.item-metadata .section-title {
    text-align: center;
    margin-top: 50px;
    margin-bottom: 50px;
    display: inline-block;
    width: 100%;
}
.metadata-info h6 , .token-metadata h5{
    color: #281489;
    margin-bottom: 5px;
}
.metadata-info {
    margin-top: 30px;
}
.metadata-info p{
    word-break: break-all;
}
.token-metadata .content {
    background: #281489;
    color: white;
    padding: 10px 15px;
    border-radius: 8px;
}
.token-metadata .content pre {
    color: white;
}
.token-metadata .content pre .key {
    color: #41ff6c;
}
.token-metadata .content pre .number {
    color: #f17700;
}
.modal-header .modal-title{
    color: #281489;
    margin: 0;
    font-size: 24px;
}
.modal-body .cart {
    display: flex;
}
.modal-body .cart .items-detail {
    width: 70%;
    padding-right: 15px;
}
.modal-body .cart .order-summary {
    width: 30%;
    background: #eee;
    padding: 15px 10px;
    border-radius: 8px;
    text-align: center;
}
.order-summary h5 {
    color: #281489;
    margin-bottom: 20px;
    font-size: 24px;
}
.order-summary .detail {
    display: flex;
    justify-content: space-between;
    font-size: 15px;
    margin-bottom: 10px;
}
.order-summary .detail .value{
    color: #1A1A1A;
}
.order-summary .detail .value small {
    color: #281489;
}
.order-summary .btn-default{
    margin: 20px auto 0;
}
.cart .table thead th{
    color: #1A1A1A;
    font-size: 15px;
    border: none;
}
.cart .items-detail .image {
    width: 80px;
    height: 80px;
    text-align: center;
    margin: 0 auto;
}
.cart .items-detail .image img{
    height: 100%;
}
.stepwizard-step p {
    margin-top: 0px;
    color:#666;
}
.stepwizard-row {
    display: flex;
    justify-content: space-between;
}
.stepwizard {
    position: relative;
}
.stepwizard .btn.disabled, .stepwizard .btn[disabled], .stepwizard fieldset[disabled] .btn {
    opacity:1 !important;
    color:#bbb;
}
.stepwizard-row:before {
    top: 14px;
    bottom: 0;
    position: absolute;
    content:" ";
    width: 100%;
    height: 1px;
    background-color: #ccc;
    z-index: 0;
}
.stepwizard-step {
    position: relative;
    text-align: left;
}
.stepwizard-step + .stepwizard-step {
    text-align: right;
}
.stepwizard-step .btn {
    background: #eee;
    color: #1a1a1a;
}
.stepwizard-step .btn-success {
    color: #fff !important;
    background-color: #28a745;
    border-color: #28a745;
}
.form-group .bootstrap-tagsinput {
    width: 100%;
    outline: none;
    box-shadow: none;
    border: 1px solid #e5e5e5;
    height: 45px;
}
.bootstrap-tagsinput .tag {
    margin-right: 2px;
    color: white;
    background: #281489;
    border-radius: 8px;
    padding: 2px 5px;
}
.main-wrapper .page-title {
    text-align: center;
    margin-bottom: 40px;
}
.create-store .stepwizard {
    margin-bottom: 20px;
    max-width: 500px;
    margin: 0 auto 20px;
}
.content-box form .btn-default{
    margin-left: auto;
    margin-top: 20px;
}
.content-box form .btn-default:hover{
    color: #fff;
}
.create-item .modal-content {
    padding: 20px;
}
.product-options {
    display: flex;
    justify-content: space-between;
}
.product-options .option {
    flex: 1;
    color: #1a1a1a;
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 8px;
    cursor: pointer;
    padding: 20px;
    margin-right: 20px;
}
.product-options .option:last-child{
    margin-right: 0;
}
.product-options .option .image {
    width: 80px;
    margin: 0 auto 10px;
}
.product-options .option a{
    color: #281489;
}
form .section-heading {
    flex-flow: column;
    align-items: flex-start;
    margin-top: 40px;
    margin-bottom: 40px;
}
form .section-heading h4{
    color: #281489;
    margin-bottom: 20px;
}
form .section-heading .nav-tabs .nav-link {
    padding: 10px 15px;
    min-width: 130px;
}
.form-text .color {
    color: #281489;
    font-family: 'Opensans-bold';
}
.content-box .collection-pagenation{
    background: none;
}
.product-item .product-wrap .actions .dropdown-menu {
    position: unset !important;
    transform: none !important;
    width: 100%;
    background: none;
    border: none;
}
.actions.buy-sell .dropdown-toggle {
    border: 1px solid #1a1a1a;
    width: 100%;
    padding: 12px 15px;
}
.actions.buy-sell .btn-default {
    margin-top: 10px !important;
    display: inline-block;
    width: 100% !important;
}
.transfer .modal-content {
    padding: 20px;
}
.transfer .modal-content .item {
    margin-bottom: 20px;
}
.transfer .modal-content .item .value {
    word-break: break-all;
}
.transfer .modal-content .btn-default {
    width: fit-content;
    margin-left: auto;
}
.content-wrapper .content-box .content {
    margin-bottom: 20px;
}
.product-image .image img{
    width: 100%;
}
.search-bar {
    background: #eee;
    padding: 10px 20px;
    border-radius: 8px;
    margin-bottom: 15px;
    width: 100%;
    display: flex;
}
.search-bar form {
    flex: 1;
}
.search-bar .search {
    width: 100%;
    max-width: 500px;
}
.search-bar .search .form-group {
    position: relative;
    margin: 0;
}
.search-bar .search .icon {
    justify-content: center;
    position: absolute;
    right: 0px;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    width: 40px;
    text-align: center;
    color: #fff;
    cursor: pointer;
    background: #251667;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}
.search-bar .filters {
    display: flex;
}
.filters .dropdown {
    margin-left: 10px;
}
.product-detail .seller-info .item .value {
    word-break: break-all;
}
.main-wrapper.store .product-image{
    margin-bottom: 20px;
}
.product-info .price {
    font-size: 20px;
    color: #281489;
    font-family: 'Opensans-bold';
    margin-top: 10px;
    display: inline-block;
}
.product-item .product-wrap .product-info p{
    margin-bottom: 10px;
}
.create-ad .modal-content .modal-title {
    margin-top: 20px;
}
.create-ad-wrapper {
    display: flex;
}
.create-ad .modal-content .ad-items-list {
    padding: 0 20px;
    width: 70%;
    max-height: 550px;
    overflow: hidden;
    overflow-y: auto;
}
.create-ad .modal-content .ad-form {
    background: #eee;
    padding: 20px;
    width: 30%;
}
.ad-items-list .collection-items.product-grid .product-item {
    width: 50%;
}
.ad-items-list .product-item .product-wrap{
    position: relative;
    padding-bottom: 0;
}
.product-item .custom-control.custom-checkbox.mb-3 {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 9;
    padding: 0;
}
.product-item .custom-control-label {
    width: 100%;
    height: 100%;
    cursor: pointer;
}
.product-item .custom-control-label::before {
    left: auto;
    right: 5px;
    width: 20px;
    height: 20px;
}
.product-item .custom-control-label::after {
    left: auto;
    right: 5px;
    width: 20px;
    height: 20px;
}
.ad-detail {
    background: #e6e6e6;
    margin-top: 15px;
    padding: 10px;
}
.ad-detail .item {
    margin-bottom: 10px;
}
.ad-detail .item span {
    margin-right: 15px;
}
.ad-form .btn-default {
    margin-left: auto;
    margin-top: 25px;
}
.ad-form h5{
    color: #281489;
    text-align: center;
}
.conservation-list {
    background: #eee;
    border-radius: 8px;
    display: inline-block;
    width: 100%;
    height: 80vh;
    overflow-y: auto;
    box-shadow: 0 0 8px 0px #26176a7a;
}
.conservation-list h4{
    text-align: center;
    color: #281489;
    margin-top: 20px;
    margin-bottom: 15px;
    position: relative;
}
.conservation-list h4 .add-new {
    position: absolute;
    right: 5px;
    top: -15px;
    cursor: pointer;
}
.conservation-list .message {
    border-bottom: 1px solid #ccc;
    padding: 10px 15px;
    display: flex;
    cursor: pointer;
}
.conservation-list .message:last-child{
    border: none;
}
.conservation-list .message.active , .conservation-list .message:hover {
    background: #281489;
    color: #fff;
}
.message-detail {
    border: 1px solid #ccc;
    box-shadow: 0 0 8px 0px #26176a7a;
    height: 80vh;
}
.message-detail .seller-title {
    padding: 20px 0;
    border-bottom: 1px solid #ccc;
    
}
.message-detail .seller-title h4 {
    text-align: center;
    color: #281489;
}
.message-detail .messages-list {
    height: 50vh;
    overflow: hidden;
    overflow-y: auto;
    padding: 10px;
    
}
.message-detail .message-box {
    display: inline-block;
    width: 100%;
    padding: 27px 20px;
    position: relative;
    padding-right: 80px;
    background:#eee;
    
}
.message-detail .message-box .form-group {
    margin: 0;
}
.message-box .send-icon {
    position: absolute;
    right: 30px;
    top: 0;
    bottom: 0;
    height: fit-content;
    margin: auto;
    border: none;
    background: no-repeat;
    font-size: 25px;
    color: #281489;
}
.message-detail .messages-list .item {
    background: #eee;
    margin-bottom: 5px;
    width: fit-content;
    padding: 10px 10px;
    border-radius: 10px;
    position: relative;
}
.message-detail .messages-list .item.right {
    background: #281489;
    color: #fff;
    margin-left: auto;
}
.message-detail .messages-list .item.right::after {
    content: "";
    position: absolute;
    right: 0;
    top: 50%;
    width: 0;
    height: 0;
    border: .9375em solid transparent;
    border-left-color: #281489;
    border-right: 0;
    border-bottom: 0;
    margin-top: -.125em;
    margin-right: -.625em;
}
.message-detail .messages-list .item.left::after {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    width: 0;
    height: 0;
    border: .9375em solid transparent;
    border-right-color: #eee;
    border-left: 0;
    border-bottom: 0;
    margin-top: -.125em;
    margin-left: -.625em;
}
.conservation-list .message .image {
    width: 40px;
    margin-right: 10px;
}
.conservation-list .message .image img {
    border-radius: 50%;
    border: 1px solid #eee;
}
.message-detail .seller-title h4 .image {
    width: 50px;
    display: inline-block;
    margin-right: 10px;
}
.message-detail .seller-title h4 .image img {
    border-radius: 50%;
    border: 1px solid #eee;
}
.user-list {
    margin-bottom: 20px;
}
.user-list .user {
    display: flex;
    border: 1px solid #eee;
    align-items: center;
    width: 250px;
    padding: 20px;
    margin-top: 10px;
}
.user-list .user:hover , .user-list .user:active , .user-list .user.active{
    display: flex;
    border: 1px solid #281489;
    background: #281489;
    color: #fff !important;
    align-items: center;
    width: 250px;
    padding: 20px;
    margin-top: 10px;
}


.user-list .user .image {
    width: 50px;
    margin-right: 10px;
}
.user-list .user .image img {
    border-radius: 50%;
}
.find-user .btn-default {
    margin-left: auto;
}
.btns-wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}
.btns-wrapper .btn-default {
    margin-left: 10px !important;
}
.change-profile-img .profile-img {
    width: 150px;
    margin: 0 auto;
    height: 150px;
}
.form-group .form-control.range {
    padding: 0;
}
.seller-profile-info {
    display: flex;
    align-items: center;
    position: relative;
    padding-bottom: 80px;
}
.seller-profile-info .bg-cover {
    height: 300px;
    width: 100%;
}
.seller-profile-info .bg-cover img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.seller-profile-info .seller-detail {
    position: absolute;
    bottom: 0;
    display: flex;
    align-items: flex-end;
    left: 10px;
    width: 100%;
}
.seller-profile-info .profile-image {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    border: 1px solid #3333;
    margin-right: 20px;
}
.seller-profile-info .profile-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}
.seller-profile-info .name{
    margin-bottom: 0;
}
.seller-profile-info .message-link {
    margin-left: 20px;
    margin-bottom: 20px;
}
.seller-profile-info .message-link .btn-primary {
    background: none;
    color: #251667;
    border: 1px solid #251667;
    font-size: 15px;
    padding: 8px 20px;
    border-radius: 8px;
    font-weight: bold;
}
.seller-detail .total-details {
    display: flex;
    margin-left: 20px;
    flex: 1;
    justify-content: flex-end;
}
.seller-detail .total-details .item{
    margin-right: 15px;
    text-align: center;
}
.total-details .item h5{
    margin: 0;
}

.profile-tabs{
    margin-top: 50px;
}
.profile-tabs .nav-tabs {
    background: rgb(37 22 103);
}
.profile-tabs .nav-tabs .nav-item .nav-link {
    color: #fff;
    padding: 10px 15px;
    font-size: 20px;
    font-family: "BebasNeue";
    letter-spacing: 0.5px;
}
.profile-tabs .nav-tabs .nav-item .nav-link.active , .profile-tabs .nav-tabs .nav-item .nav-link:hover{
    color: #251667;
    border-radius: 0;
    background: #fff;
}
.bounce {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
  }
  @-webkit-keyframes bounce {
    0%, 100% {
      -webkit-transform: translateY(0);
    }
    50% {
      -webkit-transform: translateY(-5px);
    }
  }
  @keyframes bounce {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-5px);
    }
  }
  .bounce {
    -webkit-animation-name: bounce;
    animation-name: bounce;
  }
  .starsRatings{background:url("./assets/img/stars.png") repeat-x 0 0;width:150px;float:left;margin:0 0 0 100px;}
.starsRatings input[type="radio"]{position:absolute;opacity:0;filter:alpha(opacity=0);}
.starsRatings input[type="radio"].star-5:checked ~ span{width:100%;}
.starsRatings input[type="radio"].star-4:checked ~ span{width:80%;}
.starsRatings input[type="radio"].star-3:checked ~ span{width:60%;}
.starsRatings input[type="radio"].star-2:checked ~ span{width:40%;}
.starsRatings input[type="radio"].star-1:checked ~ span{width:20%;}
.starsRatings label{display:block;width:30px;height:30px;margin:0!important;padding:0!important;text-indent:-999em;float:left;position:relative;z-index:10;background:transparent!important;cursor:pointer;}
.starsRatings label:hover ~ span{background-position:0 -30px;}
.starsRatings label.star-5:hover ~ span{width:100% !important;}
.starsRatings label.star-4:hover ~ span{width:80% !important;}
.starsRatings label.star-3:hover ~ span{width:60% !important;}
.starsRatings label.star-2:hover ~ span{width:40% !important;}
.starsRatings label.star-1:hover ~ span{width:20% !important;}
.starsRatings span{display:block;width:0;position:relative;top:0;left:0;height:30px;background:url("./assets/img/stars.png") repeat-x 0 -60px;-webkit-transition:-webkit-width 0.5s;-moz-transition:-moz-width 0.5s;-ms-transition:-ms-width 0.5s;-o-transition:-o-width 0.5s;transition:width 0.5s;}  
  .starsRating{background:url('./assets/img/stars.png') repeat-x 0 0;width:100%;float:left;margin:0px;background-size:24px 80px;width:120px;}
  .starsRating label{display:block;width:30px;height:30px;margin:0!important;padding:0!important;text-indent:-999em;float:left;position:relative;z-index:10;background:transparent!important;cursor:pointer;}
  .starsRating label ~ span{background-position:0 -60px;}
  .starsRating label.star-1 ~ span{width:10% !important;}
  .starsRating span{display:block;width:0;position:relative;top:0;left:0;height:30px;background:url('./assets/img/stars.png') repeat-x 0 -60px;-webkit-transition:-webkit-width 0.5s;-moz-transition:-moz-width 0.5s;-ms-transition:-ms-width 0.5s;-o-transition:-o-width 0.5s;transition:width 0.5s;background-size:24px 88px !important;}

  .review-detail .item {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #3333;
    margin-bottom: 20px;
}
.review-detail .item:last-child{
    border-bottom: none;
    margin: 0;
}
.product-sec h6 {
    color: #281489;
}
.review-detail .item .review-sec {
    display: flex;
    flex-direction: column;
    max-width: 550px;
}
.review-sec .customer-detail {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}
.customer-detail .profile-image {
    width: 80px;
    border: 1px solid #3333;
    border-radius: 50%;
    margin-right: 20px;
}
.customer-detail .profile-image img {
    border-radius: 50%;
}
.name-date h5 {
    margin: 0;
}
.review-sec .reviews {
    margin-bottom: 10px;
}
.content-page .page-title-section , .category .page-title-section {
    background: transparent linear-gradient(143deg, #1D114F 0%, #3A24A6 100%) 0% 0% no-repeat padding-box;
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: top center;
    margin-top: 85px;
    padding-bottom: 70px;
    text-align: center;
    padding-top: 50px;
}
section.faqs {
    margin-top: 40px;
}
section.faqs h2 {
    margin-bottom: 20px;
}
.question-answer {
    margin-bottom: 50px;
}
.question-answer .item {
    margin-bottom: 10px;
}
.question-answer .item a {
    color: #fff !important;
    display: inline-block;
    width: 100%;
    position: relative;
    background: transparent linear-gradient(143deg, #1D114F 0%, #3A24A6 100%) 0% 0% no-repeat padding-box;
    padding: 7px 15px;
    border-radius: 4px;
}
.question-answer .item .title .icon {
    font-family: 'Font Awesome 5 Free';
    font-size: 12px;
    position: absolute;
    right: 10px;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
}
.question-answer .item  .title .icon::before {
    content: "\f068";
}
.question-answer .item .collapsed .icon::before {
    content: "\f067";
}
.question-answer .content-answer {
    background: #eee;
    padding: 15px;
}
.question-answer .item .filter-content {
    margin-top: 15px;
}
form .content-wrapper .starsRatings {
    margin: 0;
    float: none;
}
.react-player__preview {
    max-width: 350px;
}
.form-switch label.switch {
    margin-bottom: 0;
    margin-left: 5px;
    margin-right: 5px;
}
.form-check.form-switch {
    display: flex;
    align-items: center;
    padding: 0;
    position: absolute;
    right: 20px;
    top: -40px;
}
.form-check.form-switch b {
    font-size: 20px;
}
.price-wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}
.price-wrapper .price {
    display: flex !important;
    width: auto !important;
    font-size: 16px !important;
    margin-bottom: 0 !important;
    margin-right: 5px;
}
.related-product-list .product-wrap .image {
    height: 300px;
    width: 100%;
}
.related-product-list .product-wrap .image img {
    height: 100%;
    object-fit: cover;
}
.dashboard-wrapper .collection-items .product-item .product-wrap {
    padding-bottom: 0px !important;  
}
.dashboard-wrapper.nft-list .collection-items .product-item .product-wrap {
    padding-bottom: 100px;  
}
.dashboard-wrapper.current-item .collection-items .product-item .product-wrap {
    padding-bottom: 150px;  
}
.dashboard-wrapper .product-item .product-wrap .actions.buy-sell {
    /* position: absolute; */
}
.product-item .product-wrap .actions.buy-sell {
    position: relative;
}
.skiptranslate{
    /* display: none; */
    opacity: 0;
}
#google_translate_element {
    color: transparent;
  }
  
#google_translate_element a {
    display: none;
  }
  .profile-tabs .tab-content .collection-items .product-item .product-wrap {
    padding-bottom: 90px;
}
.collection-sidebar.news .filters .item h4{
    color: #281489 !important;
}
.item .image{
    margin-bottom: 20px;
}
.content a{
    color: #281489 !important;
    font-weight: 700;
}
span.date{
    position: absolute;
    top: 0;
    right: 15px;
    background: #fff;
    padding: 12px;
    font-weight: 800;
}
.item {
    margin-bottom: 20px;
}
.news-items {
    margin-bottom: 50px;
}
.news-items .item img {
    width: 100%;
}
.breadcrumb-item {
    text-transform: capitalize;
}
.content a.btn-default{
    color: #fff !important;
}
.most-watched .item .image {
    margin-bottom: 0;
}
.product-item .product-wrap .actions .boosted {
    text-align: right;
}
.boosted .btn-link {color: #989898;padding: 0;text-decoration: none;}
.sticky-video--player--3QVPP.sticky-video--st-top-right--1xcHl iframe
{
    height: 315px !important;
}
.sticky-video--player--3QVPP.sticky-video--sticky--3LGf-.sticky-video--st-top-right--1xcHl iframe
{
    height: 215px !important;
    margin-top: 70px;
}
.sliderLeftArrow, .sliderRightArrow{
    position: absolute;
    top: 50%;
    z-index: 9;
    cursor: pointer;
    padding: 12px;
    background: #e5e5e5;
    border: 1px solid #ccc;
}
.sliderLeftArrow{left: 0;}
 .sliderRightArrow{
    right: 0;
}
.getting-started .content {
    width: 100%;
}
.getting-started .content .video div{
    max-width: 100%;
}
.footer-bottom .copyrights {
    padding: 0 20px;
}
.profile-info p {
    word-break: break-all;
}
body{
    top: unset !important;
}
div#goog-gt-tt {
    max-width: 100%;
}
.banner-area .slick-slide img {
    display: block;
    width: 100%;
}
.banner-area .slick-dots{
    z-index: 9;
    bottom: 10px;
}
.banner-area .slick-dots li button:before {
    opacity: 1;
    color: #231561;
    font-size: 14px;
}
.banner-area .slick-dots li.slick-active button:before {
    opacity: 1;
    color: white;
}
.slick-slide .image >img {
    width: 100% !important;
}
.content-page .content h3 , .content-page .content h4{
    color: #281489;
}
.content-page .content ul{
    padding-left: 30px;
    margin-top: 30px;
    margin-bottom: 30px;
}
.content-page .content ul.disc{
    list-style: disc;
}
.main-wrapper.product .product-image .react-video-thumbnail-image {
    height: 100%;
}
.collection-items .product-item .product-wrap .image .react-video-thumbnail-image {
    height: 100%;
}
@media (max-width: 1600px) {
    body {
        font-size: 15px;
    }
    .btn-default {
        padding: 10px 15px;
    }
    h1, .h1 {
        font-size: 65px;
    }
    h2, .h2 {
        font-size: 36px;
    }
    h3, .h3 {
        font-size: 32px;
    }
    h4, .h4 {
        font-size: 26px;
    }
    h5, .h5 {
        font-size: 22px;
    }
    h6, .h6 {
        font-size: 18px;
    }
    .custom-items {
        margin-left: 10px;
        margin-right: 0;
    }
    .main-padding {
        padding: 10px 50px;
    }
    .navbar .nav-item .nav-link{
        padding: 0 22px;
    }
    .navbar .navbar-brand .logo {
        width: 50px;
    }
    .artist-list .artist .content .btn-default{
        height: 50px;
    }
    .artist-list .artist .content{
        padding: 0 20px;
        bottom: 35px;
    }
    .art-commerce .content p {
        margin-top: 15px;
        margin-bottom: 30px;
        font-size: 18px;
        line-height: 35px;
    }
    .art-commerce .content .btn-default {
        height: 50px;
    }
    .art-commerce.main-padding{
        padding-bottom: 150px;
    }
    .art-commerce::before {
        background-size: contain;
        top: 40px;
        height: calc(100% - 190px);
        width: calc(100% - 100px);
        left: 50px;
    }
    .timer-wrapper .timer{
        font-size: 32px;
    }
    .creater-investor {
        padding: 0 0 50px;
    }
    .about-why .content p {
        margin-bottom: 20px;
    }
    .items--list .item .content{
        width: calc(100% - 30px);
        height: calc(100% - 35px);
    }
    .items--list .item .content .detail{
        padding: 10px 12px;
    }
    .getting-started{
        padding: 70px 30px 0;
    }
    .points{
        margin-bottom: 30px;
        margin-top: 30px;
    }
    .points ul li{
        font-size: 15px;
        padding-left: 20px;
    }
    .section-heading .nav-tabs .nav-link {
        margin-right: 7px;
        padding: 12px 20px;
        min-width: 140px;
    }
    .btn-default .icon, .btn .icon {
        margin-right: 5px;
    }
    .btn-default .icon img, .btn .icon img {
        width: 16px;
    }
    .section-heading .nav-tabs .nav-link img {
        margin-right: 7px;
        width: 16px;
    }
    .artist-detail {
        padding: 15px;
    }
    .artist-detail .number span {
        width: 38px;
        height: 38px;
        font-size: 16px;
    }
    .check-range .items--list .item .content .detail>div {
        padding: 15px;
        min-width: 130px;
        margin-right: 10px;
    }
    .check-range .items--list .item .content .detail>div p {
        font-size: 14px;
    }
    .check-range .items--list .item .content {
        width: calc(100% - 30px);
        height: calc(100% - 40px);
        top: 15px;
    }
    section.nexus-guide {
        padding: 80px 0 70px;
    }
    .blockchain .h1-title {
        font-size: 65px;
        line-height: 75px;
        margin-bottom: 40px;
    }
    .blockchain .content button {
        margin-top: 30px;
    }
    .follow-us {
        text-align: center;
        margin-bottom: 60px;
    }
    .follow-us .btn.follow {
        font-size: 35px;
        height: 110px;
    }
    .social-links {
        margin-bottom: 40px;
    }
    .footer-links {
        margin-bottom: 0;
    }
    footer {
        padding-top: 430px;
    }
    .blockchain ~ .footer{
        margin-top: -300px;
    }
    .chat-icon{
        right: 0;
    }
    .copyrights p {
        font-size: 14px;
    }
    .account-dropdown .dropdown-menu {
        left: auto;
        right: 0;
    }
    .collection-items .product-item .product-wrap .image {
        height: 280px;
    }
    .content-page .page-title-section, .category .page-title-section{
        margin-top: 75px;
    }
}
@media (max-width: 1400px) {
    .flags-dropdown {
        margin: 0 15px;
    }
    .navbar .nav-item .nav-link {
        padding: 0 20px;
    }
    .btn-default {
        padding: 10px 10px;
    }
    .collection-items.product-grid .product-item {
        width: 33.3%;
    }
    .btn-default{
        height: 55px;
    }
    .check-range .items--list.full .item {
        height: 650px;
    }
    .check-range .items--list .item{
        height: 325px;
    }
    .related-product-list.no-slider .product-item{
        width: 25%;
    }
}
@media (max-width: 1200px) {
    body {
        font-size: 14px;
    }
    h1, .h1 {
        font-size: 50px;
    }
    h2, .h2 {
        font-size: 30px;
    }
    h3, .h3 {
        font-size: 28px;
    }
    h4, .h4 {
        font-size: 22px;
    }
    h5, .h5 {
        font-size: 18px;
    }
    h6, .h6 {
        font-size: 16px;
    }
    .main-padding {
        padding: 10px 30px;
    }
    .navbar .nav-item .nav-link{
        padding: 0 12px;
    }
    .navbar .navbar-brand .logo {
        width: 40px;
    }
    .artist-list .artist .content .btn-default{
        height: 40px;
    }
    .flags-dropdown button.btn {
        padding: 0 10px;
    }
    .flags-dropdown {
        margin: 0 10px;
    }
    .artist-list .artist .content {
        padding: 0 10px;
        bottom: 25px;
    }
    .artist-list .artist .content h2{
        margin-bottom: 15px;
    }
    .art-commerce .content p> br{
        display: none;
    }
    .art-commerce.main-padding {
        padding-bottom: 100px;
    }
    .art-commerce::before {
        height: calc(100% - 140px);
        width: calc(100% - 60px);
        left: 30px;
    }
    .timer-wrapper .timer {
        font-size: 25px;
    }
    .items--list .item .content .detail{
        flex-flow: column;
        text-align: center;
        align-items: center;
    }
    .items--list .item .content .detail>div{
        margin-bottom: 10px;
    }
    .section-heading .nav-tabs .nav-link{
        min-width: 120px;
    }
    .check-range .section-heading{
        margin-bottom: 10px;
    }
    .content .cards .boxes-wrapper {
        width: 180px;
        height: 170px;
        margin-right: 20px;
    }
    .content .cards .boxes {
        padding: 20px;
    }
    .content .cards .points {
        /* width: 180px; */
        margin-right: 20px;
    }
    .check-range .items--list .item .content .detail>div {
        background: #22145ea3;
        margin-right: 0;
        width: 100%;
        margin-bottom: 5px;
        padding: 5px 10px;
    }
    /* .check-range .items--list .item .content .detail, .check-range .items--list .item .content .artist-detail{
        display: flex;
        padding-bottom: 5px;
        align-items: end;
    } */
    .blockchain .h1-title {
        font-size: 50px;
        line-height: 65px;
        margin-bottom: 30px;
    }
    .blockchain .content {
        margin-right: 50px;
    }
    footer {
        padding-top: 400px;
        margin-top: -150px;
    }
    .blockchain ~ .footer{
        margin-top: -290px;
    }
    .follow-us .btn.follow {
        font-size: 30px;
        height: 100px;
    }
    .follow-us {
        margin-bottom: 50px;
    }
    .social-links {
        margin-bottom: 30px;
    }
    .footer-links {
        margin-bottom: 0;
    }
    .chat-icon {
        right: 30px;
    }
    .create-ad .modal-content .ad-items-list {
        width: 65%;
    }
    .create-ad .modal-content .ad-form {
        width: 35%;
    }
    .collection-items .product-item .product-wrap .image {
        height: 260px;
    }
    .check-range .items--list.full .item {
        height: 600px;
    }
    .check-range .items--list .item{
        height: 300px;
    }
    .art-commerce .content {
        margin-bottom: 50px;
    }
    .art-commerce .content p {
        margin-bottom: 20px;
        font-size: 18px;
        line-height: 28px;
    }
    .content .cards .boxes .h3{
        font-size: 35px;
    }
    .content-page .page-title-section, .category .page-title-section{
        margin-top: 0;
    }
}
@media (max-width: 991px) {
    
    h1, .h1 {
        font-size: 40px;
    }
    h2, .h2 {
        font-size: 28px;
    }
    h3, .h3 {
        font-size: 26px;
    }
    h4, .h4 {
        font-size: 20px;
    }
    h5, .h5 {
        font-size: 16px;
    }
    h6, .h6 {
        font-size: 15px;
    }
    .navbar-toggler {
        background: transparent linear-gradient(90deg, #643DAA 0%, #E85993 100%) 0% 0% no-repeat padding-box;
        color: white;
        padding: 2px 3px;
        margin-left: auto;
    }
    .navbar-toggler-icon{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: auto;
    }
    section.nexus-guide {
        padding: 50px 0 60px;
    }
    .art-commerce .content p {
        font-size: 16px;
        line-height: 30px;
    }
    .timer-wrapper {
       padding: 15px
    }
    .timer-wrapper .timer {
        font-size: 22px;
    }
    .timer-wrapper .timer span small {
        font-size: 8px;
    }
    .about-icons {
        display: flex;
        margin-top: 50px;
        justify-content: space-around;
    }
    .about-icons .icon {
        width: 100px;
    }
    .about-icons .slider {
        -webkit-appearance: none;
        width: 100px;
    }
    .about-icons .slider::-webkit-slider-thumb {
        width: 50px;
        height: 50px;
    }
    .about-icons .slider::-moz-range-thumb {
        width: 50px;
        height: 50px;
    }
    .about-icons .icon .image img {
        width: 50px;
    }
    .points {
        margin-top: 20px;
    }
    .points ul li{
        font-size: 14px;
    }
    .chat-icon img {
        width: 70px;
    }
    .most-watched .section-heading {
        margin-bottom: 10px;
        margin-top: 10px;
    }
    .check-range .items--list .item .content .detail, .check-range .items--list .item .content .artist-detail{
        display: none;
    }
    .blockchain .h1-title {
        font-size: 40px;
        line-height: 50px;
        margin-bottom: 20px;
    }
    .follow-us .btn.follow {
        font-size: 26px;
        width: 350px;
    }
    .navbar .navbar-collapse {
        position: absolute;
        left: 0;
        top: 65px;
        background: rgba(29,17,79,.82);
        width: 100%;
        padding-bottom: 10px;
        padding-top: 10px;
        border-top: 1px solid #ffffff1a;
        padding: 10px 20px;
    }
    .navbar-nav .nav-item {
        padding: 10px 0;
        border-bottom: 1px solid #ffffff1a;
    }
    .navbar-nav .nav-item:last-child{
        border: none;
    }
    .navbar-nav .nav-item .nav-link {
        padding: 0;
        width: 100%;
    }
    .navbar-nav .nav-item .nav-link:hover{
        transform: none;
    }
    .navbar-nav .nav-item .nav-link:hover::before{
        display: none;
    }
    .login-signup .modal-content {
        border-top-left-radius: 0;
    }
    .login-signup-wrapper .nav-pills .nav-link.active, .login-signup-wrapper .nav-pills .show>.nav-link {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
    .login-signup-wrapper .image {
        display: none;
    }
    .login-signup .modal-body .content-wrapper {
        height: 70vh;
    }
    .login-signup-wrapper .nav-pills {
        position: absolute;
        right: 0;
        left: 0;
        bottom: 0;
        top: auto;
        margin: auto;
        justify-content: center;
        display: -webkit-inline-box;
    }
    .check-range .items--list .item{
        padding-right: 0;
    }
    .collection-filters{
        margin-top: 15px;
    }
    .collection-sidebar{
        max-width: 300px;
        /* margin: 0 auto; */
    }
    .modal-body .product-image {
        margin-bottom: 50px;
    }
    .btn-default{
        height: 50px;
    }
    .dashboard-wrapper.main-padding, .main-wrapper.main-padding {
        padding-top: 100px;
    }
    .modal-body .cart {
        flex-flow: column;
    }
    .modal-body .cart .items-detail{
        width: 100%;
        padding-right: 0;
    }
    .modal-body .cart .order-summary{
        width: 100%;
    }
    .dashboard-wrapper .collection-items.product-grid .product-item {
        width: 50%;
    }
    .related-products {
        margin-top: 50px;
    }
    .conservation-list .search-bar {
        padding: 10px;
    }
    .conservation-list .search-bar .form-control {
        font-size: inherit;
    }
    .create-ad .modal-content .ad-items-list {
        width: 100%;
    }
    .create-ad .modal-content .ad-form {
        width: 100%;
    }
    .create-ad-wrapper {
       flex-flow: column;
    }
    .collection-items .product-item .product-wrap .image {
        height: 250px;
    }
    .check-range .items--list.full .item {
        height: 500px;
    }
    .check-range .items--list .item{
        height: 250px;
    }
    .related-product-list.no-slider .product-item{
        width: 33.3%;
    }
}
@media (max-width: 767px) {
    h1, .h1 {
        font-size: 35px;
    }
    h2, .h2 {
        font-size: 26px;
    }
    h3, .h3 {
        font-size: 24px;
    }
    h4, .h4 {
        font-size: 18px;
    }
    .main-padding{
        padding: 10px 20px;
    }
    .art-commerce.main-padding {
        padding-bottom: 40px;
        background: #231561;
    }
    .art-commerce::before{
        display: none;
    }
    .creater-investor {
        padding: 50px 0 50px;
    }
    .timer-wrapper {
        padding: 25px;
        margin-top: 50px;
        height: auto;
        max-width: 400px;
    }
    .about-why {
        padding: 0 0 30px;
    }
    .about-why .content {
        margin-bottom: 60px;
    }
    .check-range .items--list .item .image img {
        width: 100%;
    }
    .check-range .items--list .col-md-8.pr-0 {
        padding: 0;
    }
    .blockchain .content {
        margin-right: 0;
        margin-bottom: 50px;
    }
    .blockchain .image{
        margin: 0 auto;
    }
    .section-heading .nav-tabs .nav-link {
        margin-right: 5px;
        padding: 12px 10px;
        min-width: 80px;
    }
    .section-heading .nav-tabs .nav-link img {
        margin-right: 5px;
        width: 15px;
    }
    footer {
        padding-top: 70px;
        margin-top: 70px;
        background: #201359;
    }
    .footer-links ul li a {
        padding: 0 8px;
    }
    .follow-us .btn.follow {
        font-size: 24px;
        width: 300px;
        height: 90px;
    }
    .items--list .item{
        width: 50%;
        padding: 0;
    }
    .check-range .items--list .item:hover .content .detail {
        text-align: center;
        align-items: center;
    }
    .collection-items.product-grid .product-item{
        width: 50%;
    }
    .main-menu .navbar {
        filter: drop-shadow(-4.17px 4.32px 5px #00000085);
    }
    .collection-items.product-list .product-item .product-wrap .product-info{
        padding: 10px;
    }
    .collection-items.product-list .product-item .product-wrap .actions {
        padding: 10px;
    }
    .item-metadata .section-title {
        margin-top: 30px;
        margin-bottom: 20px;
    }
    .metadata-info {
        margin-top: 10px;
    }
    .dashboard-wrapper .content-wrapper {
        margin-top: 30px;
    }
    .dashboard-wrapper .sidebar{
        max-width: 300px;
    }
    form .section-heading .nav-tabs .nav-link {
        margin-bottom: 5px;
    }
    .related-products {
        margin-top: 30px;
    }
    .message-detail{
        margin-top: 30px;
    }
    .blockchain ~ .footer {
        margin-top: 70px;
    }
    .collection-items .product-item .product-wrap .image {
        height: 230px;
    }
    .content .cards .boxes .h3{
        font-size: 30px;
    }
    .related-product-list.no-slider .product-item{
        width: 50%;
    }
    .form-group .form-control{
        font-size: 14px;
    }
    section.about-why .content {
        text-align: left;
    }
}
@media (max-width: 575px) {
    h1, .h1 {
        font-size: 30px;
    }
    h2, .h2 {
        font-size: 25px;
    }
    h3, .h3 {
        font-size: 22px;
    }
    h4, .h4 {
        font-size: 17px;
    }
    .main-padding{
        padding: 10px 15px;
    }
    .custom-items button.btn-default {
        display: none !important;
    }
    .navbar .navbar-brand{
        margin-right: 5px;
    }
    .flags-dropdown {
        margin: 0 5px;
    }
    .custom-items{
        margin-left: 0;
    }
    .content .cards {
        display: flex;
        justify-content: space-around;
    }
    .content .cards .boxes-wrapper{
        margin-right: 0;
        margin-bottom: 20px;
    }
    .art-commerce.main-padding{
        padding: 20px;
    }
    .art-commerce.main-padding .content{
        margin-bottom: 30px;
    }
    .about-icons {
        flex-wrap: wrap;
        justify-content: space-around;
    }
    .about-icons .icon {
        width: 100px;
        margin-bottom: 40px;
    }
    .about-why .content {
        margin-bottom: 20px;
    }
    .getting-started {
        padding: 40px 10px;
    }
    .getting-started .content{
        margin-bottom: 30px;
    }
    .content .cards .boxes-wrapper {
        width: 120px;
        height: 120px;
    }
    .content .cards .points {
        /* width: 140px; */
    }
    .timer-wrapper .timer {
        font-size: 20px;
    }
    .follow-us .btn.follow {
        font-size: 22px;
        width: 250px;
        height: 80px;
    }
    .check-range .section-heading {
        flex-flow: column;
        align-items: flex-start;
    }
    .check-range .section-heading h2{
        margin-bottom: 15px;
    }
    .chat-icon{
        bottom: 40px;
    }
    .chat-icon img {
        width: 50px;
    }
    .custom-items .login-signup {
        display: none;
    }
    .custom-items .mobile.login-signup {
        display: block;
    }
    .nexus-guide .section-heading::before{
        display: none;
    }
    .videos-slider .slick-arrow {
        top: -73px;
        width: 10px;
    }
    .footer-links ul li{
        margin-bottom: 5px;
    }
    .items--list .item{
        width: 100%;
        padding: 0;
    }
    .collection-items.product-grid .product-item{
        width: 100%;
        margin: 0 auto 20px;
    }
    .btn-default{
        height: 45px;
    }
    .collection-items.product-list .product-item .product-wrap{
        flex-flow: column;
    }
    .collection-items.product-list .product-item .product-wrap .image , .collection-items.product-list .product-item .product-wrap .product-info , .collection-items.product-list .product-item .product-wrap .actions{
        width: 100%;
    }
    .collection-items.product-list .product-item .product-wrap .image img {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        border-bottom-left-radius: 0;
        width: 100%;
    }
    .collection-items.product-grid .product-item .product-wrap .image img{
        width: 100%;
    }
    .collection-sidebar{
        max-width: 100%;
    }
    .custom-items .mobile.login-signup .account-dropdown .btn {
        padding-left: 5px;
        padding-right: 5px;
        font-size: 12px;
    }
    .flags-dropdown button.btn {
        padding: 0 5px;
    }
    .content-box{
        padding: 15px;
    }
    .button-group{
        flex-flow: column;
    }
    .button-group button{
        margin-bottom: 10px !important;
    }
    .button-group .btn-secondary{
        height: 45px;
        padding: 10px 15px;
        font-size: inherit;
    }
    .dashboard-wrapper .sidebar {
        max-width: 100%;
    }
    .product-options .option{
        padding: 10px;
    }
    .main-wrapper.main-padding.create-store {
        padding: 100px 0px 0;
    }
    .dashboard-wrapper .collection-items.product-grid .product-item {
        width: 100%;
    }
    .message-detail .message-box{
        padding-right: 60px;
    }
    .message-box .send-icon{
        right: 20px;
    }
    .create-ad .modal-content .ad-items-list {
        padding: 0 10px;
    }
    .ad-items-list .collection-items.product-grid .product-item{
        padding: 0 5px;
    }
    .about-why .follow-us .btn.follow {
        font-size: 16px;
        padding: 5px 20px;
    }
    .points ul li {
        font-size: 12px;
        padding-left: 15px;
        line-height: 16px;
    }
    .content .cards .boxes .h3{
        font-size: 24px;
    }
    .related-product-list.no-slider .product-item{
        width: 100%;
    }
    .form-group .form-control{
        font-size: 12px;
    }
    #header .fixed-top{
        position: inherit !important;
    }
}
.ant-select-selection-item{
    display: none !important;
}
.ant-select-selector{
    display: none !important;
}
.ant-pagination-options{
    display: none !important;
}

.ant-pagination{
    justify-content: center !important;
    display: flex !important;
    width: 100%;
}

/* .drag-drop {
    animation-duration: 2s;
    animation-iteration-count: infinite;
    transform-origin: bottom;
    animation-name: drag-drop;
    animation-timing-function: linear;
}
@keyframes drag-drop {
    0%   { transform: translateX(0); }
    50%  { transform: translateX(65px); }
    100% { transform: translateX(0); }
} */



.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }
  
  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #2196F3;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }

  .spinner-border{
    color: #f4129c !important;
    display: flex;
    flex: 1;
    margin: auto;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .error-ad{
      color: red;
      display: flex;
      justify-content: center;
      text-align: center;
  }
.flags-dropdown #google_translate_element {
    background-color: rgb(255, 255, 255) !important;
}
.goog-te-gadget{
    /* display: none !important; */
}
.goog-te-combo{
    border: 1px solid black !important;
}
.recharts-legend-item{
    display: none !important;
}
.rotate-45 {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    animation: rotate 1s infinite;
}

@keyframes rotate {
    100% {
        transform: rotateY(180deg);
    }
}
.list-loader{
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
}
.btn-link{
    text-decoration: none !important;
}
.no-content-class{
    display: flex;
    justify-content: center;
    text-align: center;
}
.no-content-class h4{
    color:#201359;
}
.react-tagsinput-error{
    background-color: #fff;
    border: 1px solid #dc3545;
    overflow: hidden;
    padding-left: 5px;
    padding-top: 5px;
}